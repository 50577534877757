const httpGet = url =>
    fetch(url, {
        method: "GET",
    })
        .then(response => response.json())
        .then(data => {
            return data;
        })
        .catch(() => {
            throw new Error("Cannot get file data");
        });

const newTimeQueryStr = () => "t=" + new Date().getTime();

const queryParamsForArray = (vals, queryField) =>
    vals.map(v => queryField + "=" + v).join("&");

export const LamppostService = ({ apiUrl }) => {
    const lpIdUrl = (apiUrl, lpId) => `${apiUrl}/lampposts/${lpId}`;

    return {
        /**
         * get lamppost data from filePath
         */
        getLamppostData: () => httpGet("/data/data.json"),
        getLampposts: () => httpGet("/data/lampposts.json"),
        getLamppostTimeRange: ({ lamppostId }) =>
            httpGet(
                `${lpIdUrl(apiUrl, lamppostId)}/timerange?${newTimeQueryStr()}`
            ),
        probeMetricsByLamppost: ({
            lamppostId,
            type,
            from,
            to,
            metrics,
            lang,
        }) =>
            httpGet(
                `${lpIdUrl(
                    apiUrl,
                    lamppostId
                )}/metrics/download?type=${type}&from=${from}&to=${to}&lang=${lang}&${queryParamsForArray(
                    metrics,
                    "metric"
                )}&probing=1&${newTimeQueryStr()}`
            ),
        downloadMetricsByLamppost: ({
            lamppostId,
            type,
            from,
            to,
            metrics,
            lang,
        }) =>
            window.open(
                `${lpIdUrl(
                    apiUrl,
                    lamppostId
                )}/metrics/download?type=${type}&from=${from}&to=${to}&lang=${lang}&${queryParamsForArray(
                    metrics,
                    "metric"
                )}&${newTimeQueryStr()}`,
                "_blank"
            ),
        probeMetricsByPollutant: ({
            pollutant,
            type,
            from,
            to,
            lamppostIds,
            lang,
        }) =>
            httpGet(
                `${apiUrl}/pollutants/${pollutant}/metrics/download?type=${type}&from=${from}&to=${to}&lang=${lang}&${queryParamsForArray(
                    lamppostIds,
                    "lamppost_id"
                )}&probing=1&${newTimeQueryStr()}`
            ),
        downloadMetricsByPollutant: ({
            pollutant,
            type,
            from,
            to,
            lamppostIds,
            lang,
        }) =>
            window.open(
                `${apiUrl}/pollutants/${pollutant}/metrics/download?type=${type}&from=${from}&to=${to}&lang=${lang}&${queryParamsForArray(
                    lamppostIds,
                    "lamppost_id"
                )}&${newTimeQueryStr()}`,
                "_blank"
            ),
        getYearsOfYearlyAvgMetrics: () =>
            httpGet(
                `${apiUrl}/metrics/yearly-averages/years?${newTimeQueryStr()}`
            ),
    };
};
