import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { useTranslation } from "react-i18next";

import Layout from "../layout";
import Loading from "../loading";
import { IconLink } from "./download-common";
import { LamppostService } from "../../services/lamppost";
import { windowGtagEvent } from "../../utils/gtag";
import { useSettings } from "../../hooks/settings";

import styles from "./download-year.module.less";
import IconBack from "../../images/icon_back.png";
import IconExport from "../../images/icon_export_excel.gif";

const DownloadByYear = ({ lang, path }) => (
    <Layout lang={lang} path={path} i18nSEO="DataDownloadByYear">
        <View lang={lang} />
    </Layout>
);
DownloadByYear.propTypes = {
    lang: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
};

export default DownloadByYear;

const yearlyCsvUrl = (year, lang) => `/data/yearly-csv/${year}_${lang}.csv`;

const View = ({ lang }) => {
    const { t } = useTranslation();
    const lamppostService = LamppostService({ apiUrl: useSettings().apiUrl });

    const [loading, setLoading] = useState(true);
    const [years, setYears] = useState([]);

    useEffect(() => {
        lamppostService
            .getYearsOfYearlyAvgMetrics()
            .then(d => setYears(d.years))
            .catch(err => console.error(err))
            .finally(() => setLoading(false));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const YearsView = () => (
        <div className={styles.years}>
            {years.map(y => (
                <div key={"y" + y.display_year}>
                    <a
                        href={yearlyCsvUrl(y.display_year, lang)}
                        className={styles.button}
                        aria-label={t("DownloadCsv") + ": " + y.display_year}
                        onClick={() => {
                            windowGtagEvent(
                                "download",
                                "download_by_year",
                                `${y.display_year}_${lang}`
                            );
                            return true;
                        }}
                    >
                        <div className="mx-auto flexrow-center">
                            <span className="flex-1"></span>
                            <img
                                className="mr2"
                                src={IconExport}
                                alt={t("DownloadCsv")}
                            />
                            {y.display_year}
                            <span className="flex-1"></span>
                        </div>
                    </a>
                </div>
            ))}
        </div>
    );

    return (
        <div className="sections">
            <div className="header">
                <div className="title">{t("DataDownloadByYear")}</div>
                <div className="links">
                    <IconLink
                        to={`/${lang}/download/devices`}
                        label={t("DataDownloadByDevice")}
                    />
                    <IconLink
                        to={`/${lang}/download/parameter`}
                        label={t("DataDownloadByParam")}
                    />
                </div>
            </div>

            {loading ? (
                <Loading />
            ) : years.length === 0 ? (
                <div className={styles.noData}>{t("NoDataAvail")}</div>
            ) : (
                <YearsView />
            )}

            <div className={styles.btnGroup}>
                <Link
                    className="btnIcon"
                    aria-label={t("Back")}
                    to={`/${lang}/download`}
                >
                    <img src={IconBack} alt="" />
                    <div>{t("Back")}</div>
                </Link>
            </div>
            <div className="footnote">{t("EPDHK")}</div>
        </div>
    );
};
