import PropTypes from "prop-types";
import React from "react";
import { Link } from "gatsby";

import { ChevronRightFill } from "../icons";

const IconLink = ({ to, label }) => (
    <div>
        <Link to={to} className="flexrow-reverse">
            {label}
            <div className="rightArrow">
                <ChevronRightFill />
            </div>
        </Link>
    </div>
);
IconLink.propTypes = {
    to: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
};

export { IconLink };
