import { useStaticQuery, graphql } from "gatsby";

export const useSettings = () => {
    return useStaticQuery(graphql`
        query SettingsQuery {
            site {
                siteMetadata {
                    title
                    description
                    apiUrl
                    mapApiKey
                }
            }
        }
    `).site.siteMetadata;
};
